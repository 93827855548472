export function asyncPromise(func) {
  return new Promise(async (resolve, reject) => {
    try {
      func(resolve, reject)
    } catch(e) {
      reject(e)
    }
  })
}

export function asyncEmpty(extra) {
  return {
    loadStatus: 'empty',
    loadError: null,
    loadResult: null,
    isEmpty: true,
    isPending: false,
    isError: false,
    isDone: false,
    ...(extra || {}),
  }
}

export function asyncPending(extra) {
  return {
    loadStatus: 'pending',
    loadError: null,
    loadResult: null,
    isEmpty: false,
    isPending: true,
    isError: false,
    isDone: false,
    ...(extra || {}),
  }
}

export function asyncError(err, extra) {
  // Thrown error from the server might have additional data and axios is
  // puting that data in err.response.data.
  // The UI should be able to read both loadError and loadError.message.
  let readableError = err
  if ((err && err.response && typeof err.response.data === 'object')) {
    readableError = err.response.data
  } else if (err && err.message) {
    readableError = err.message
  }
  return {
    loadStatus: 'error',
    loadError: readableError,
    loadResult: null,
    isEmpty: false,
    isPending: false,
    isError: true,
    isDone: false,
    ...(extra || {}),
  }
}

export function asyncDone(res, extra, done) {
  return {
    loadStatus: 'done',
    loadError: null,
    loadResult: res,
    isEmpty: false,
    isPending: false,
    isError: false,
    isDone: true,
    ...(extra || {}),
    ...(done || {}),
  }
}

const funcs = {
  asyncDone,
  asyncEmpty,
  asyncError,
  asyncPending,
  asyncPromise
}

export default funcs