import axios from 'axios'
import React, { useState } from 'react'

import LoadingResult from '../components/LoadingResult'
import a from '../redux/asyncs'

export default function Settings() {
  const [sendPNRes, setSendPNRes] = useState(a.asyncEmpty())

  const sendPN = async () => {
    if (window.confirm('Това ще изпрати нотификейшън до всички потребители на приложението. Сигурен си ли?')) {
      setSendPNRes(a.asyncPending())

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/notification/sendifnews/daily`)
        setSendPNRes(a.asyncDone(res.data))
      } catch (e) {
        setSendPNRes(a.asyncError(e))
      }
    }
  }
  
  return (
    <div>
      {sendPNRes.isEmpty && (
        <button onClick={sendPN}>Изпрати дневен нотификейшън</button>
      )}
      <LoadingResult async={sendPNRes} style={{justifyContent: 'left'}} />
    </div>
  )
}
