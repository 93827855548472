import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import LogoutButton from './LogoutButton'
import LoadingResult from './LoadingResult'

export default function Nav() {

  const { pathname } = useLocation()
  const classes = useStyles()
  const currentWeekNews = useSelector(state => state.news.currentWeekNews)
  const currentNewsletter = useSelector(state => state.news.currentNewsletter)
  
  return (
    <LoadingResult async={currentWeekNews}>
      <nav>
        <ul className={classes.ul}>
          <li><NavLink to="/news" className={['/news', '/'].includes(pathname) ? 'active' : ''}>Новини</NavLink></li>
          <li><NavLink to="/stats">Статистика</NavLink></li>
          <li><NavLink to="/settings">Настройки</NavLink></li>
          <li><LogoutButton /></li>
        </ul>
      </nav>
    </LoadingResult>
  )
}

const useStyles = createUseStyles({
  ul: {
    listStyle: 'none',
    padding: '0 0 1.5em',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    borderBottom: '1px solid #ccc',

    '& > li:last-child': {
      marginLeft: 'auto'
    },

    '& > li > a': {
      color: 'blue',

      '&:hover': {
        textDecoration: 'none'
      },

      '&.active': {
        color: 'black',
        textDecoration: 'none',
        cursor: 'default',
      }
    }
  },
})
