import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import axios from 'axios'
import {createUseStyles} from 'react-jss'
import { FaTrash, FaEdit } from 'react-icons/fa'

import LoadingResult from '../../components/LoadingResult'
import { updateItemFromCurrentWeekNews, fetchCurrentWeekNews } from '../../redux/reducers/news'
import a from '../../redux/asyncs'
dayjs.extend(utc)

export default function CurrentWeekNews(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentWeekNews = useSelector(state => state.news.currentWeekNews)

  const navigate = useNavigate()

  const [submitRes, setSubmitRes] = useState(a.asyncEmpty())

  const handleChangeInWeekly = async (_id, inWeekly) => {
    dispatch(updateItemFromCurrentWeekNews({_id, inWeekly}))

    setSubmitRes(a.asyncPending())

    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/news/${_id}`, { inWeekly })
      dispatch(fetchCurrentWeekNews(false))
      setSubmitRes(a.asyncDone(res.data))
    } catch (e) {
      setSubmitRes(a.asyncError(e))
    }
  }

  const confirmDelete = async (_id) => {
    const conf = window.confirm('Това ще изтрие новината. Сигурен ли си?')
    if (conf) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/news/${_id}`)
        dispatch(fetchCurrentWeekNews(false))
      } catch (e) {
        
      }
    }
  }

  return (
    <div>
      <h2>Новини за следващия седмичен бюлетин</h2>

      <small>Това са новините, които са въведени <strong>след изпращането на последното седмично известие</strong>, т.е. все още не са участвали в седмичен бюлетин.</small>

      <LoadingResult async={currentWeekNews}>
        <table>
          <thead>
            <tr>
              <th width="100%">Новина</th>
              <th>Тема</th>
              <th>Важност</th>
              <th>Дата</th>
              <th>Част от седмичния бюлетин?</th>
              <th>Likes</th>
              <th>Изтрий</th>
            </tr>
          </thead>
          <tbody>
            {(currentWeekNews.loadResult || []).map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.url ? (
                    <a className={classes.link} href={item.url} target="_blank" rel="noreferrer">{item.title}</a>
                  ) : (
                    item.title
                  )}</td>
                  <td>{item.topic}</td>
                  <td>{item.importance}</td>
                  <td style={{whiteSpace: 'nowrap'}}>{dayjs(item.date).format('YYYY-MM-DD HH:mm')}</td>
                  <td>
                    <input type="checkbox" checked={item.inWeekly} onChange={() => {
                      handleChangeInWeekly(item._id, !item.inWeekly)
                    }}/>
                  </td>
                  <td>{(item.likes || []).length}</td>
                  <td>
                  <FaEdit style={{cursor: 'pointer', marginRight: 10}} onClick={() => {
                    navigate(`/news/edit/${item._id}`)
                  }} />
                  <FaTrash style={{cursor: 'pointer'}} onClick={() => {
                    confirmDelete(item._id)
                  }} />
                  </td>
                </tr>
              )
            })}
            {(currentWeekNews.loadResult || []).length === 0 && (
              <tr>
                <td colSpan="4">Няма добавени новини от последното седмично известие</td>
              </tr>  
            )}
          </tbody>
        </table>
      </LoadingResult>
      <LoadingResult async={submitRes} />
      {/* <Success message={message} />
      <Error error={error} /> */}
    </div>
  );
}

const useStyles = createUseStyles({
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
})
