import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'

import './index.css'
import App from './App'
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <Provider store={store}>
      <Auth0Provider
        domain='dev-g7qur2t3souew6i8.eu.auth0.com'
        clientId='QeQX7dVKWKXhIKsLxDnYHoWc6uDUxnEr'
        // domain='https://admin.briefy.me'
        // clientId='182445041233-6nkdjctpaagp11s32v19gu8i5n6lpuvj.apps.googleusercontent.com'
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </Provider>
    </RecoilRoot>
  // </React.StrictMode>
);
