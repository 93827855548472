import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

export default function Stats() {
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <div>
      <nav>
        <ul className={classes.ul}>
          <li><NavLink to="/stats/views" className={pathname === '/stats' &&  'active'}>Уникалния посещения</NavLink></li>
          <li><NavLink to="/stats/users">Потребители</NavLink></li>
          <li><NavLink to="/stats/ads">Реклами</NavLink></li>
        </ul>
      </nav>

      <Outlet />
    </div>
  )
}

const useStyles = createUseStyles({
  ul: {
    listStyle: 'none',
    padding: '0 0 1.5em',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    borderBottom: '1px solid #ccc',

    '& > li > a': {
      color: 'blue',

      '&:hover': {
        textDecoration: 'none'
      },

      '&.active': {
        color: 'black',
        textDecoration: 'none',
        cursor: 'default',
      }
    }
  },
})
