import React, { useState, useEffect } from 'react'
import {createUseStyles} from 'react-jss'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil'

import LoadingResult from '../../components/LoadingResult'
import a from '../../redux/asyncs'
import { fetchCurrentWeekNews } from '../../redux/reducers/news'
import { newsItemQuery } from '../../recoil/news'
import emojis from '../../lib/emojis'

export const topics = [
  {
    key: "stocks",
    label: "Акции"
  },
  {
    key: "crypto",
    label: "Крипто"
  },
  {
    key: "economics",
    label: "Икономика"
  }
]

export const importances = [
  {
    key: "1",
    label: "Най-малко важна",
  },
  {
    key: "2",
    label: "По-малко важна",
  },
  {
    key: "3",
    label: "Средно важна",
  },
  {
    key: "4",
    label: "По-важна",
  },
  {
    key: "5",
    label: "Най важна",
  },
]

export default function AddEditNewsItem() {
  let { userId } = useParams()
  const currentNewsItem = useRecoilValue(newsItemQuery(userId))
  const currentNewsItemRefresh = useRecoilRefresher_UNSTABLE(newsItemQuery(userId))

  const defaultFormData = {
    title: '',
    topic: 'stocks',
    importance: '3',
    url: '',
    source: ''
  }

  const [submitRes, setSubmitRes] = useState(a.asyncEmpty())

  const dispatch = useDispatch()
  const classes = useStyles()
  const [formData, setFormData] = useState(userId && currentNewsItem ? currentNewsItem : defaultFormData)

  // If we open an edit page for some newsitem and then click on the "Add" page,
  // the formData keeps the old news item. This removes it
  useEffect(() => {
    if (!userId && formData._id) {
      setFormData(defaultFormData)
    }
  }, [userId])

  const handleChange = (event) => {
    let newData = {
      ...formData,
      [event.target.name]: event.target.value
    }

    if (event.target.name === 'url') {
      let source = event.target.value.replace('https://', '').replace('http://', '').replace('www.', '')
      newData.source = source.substring(0, source.indexOf('/'))
    }
    
    setFormData(newData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setSubmitRes(a.asyncPending())

    try {
      const res = currentNewsItem ? (
        await axios.put(`${process.env.REACT_APP_API_URL}/news/${currentNewsItem._id}`, formData)
      ) : (
        await axios.post(`${process.env.REACT_APP_API_URL}/news`, formData)
      )
      dispatch(fetchCurrentWeekNews(false))
      setFormData(defaultFormData)
      setSubmitRes(a.asyncDone(res.data))

      // If we've made an edit, refresh the recoil state for the current user so that if we open it again it has the new data
      if (currentNewsItem) {
        currentNewsItemRefresh()
      }
    } catch (e) {
      setSubmitRes(a.asyncError(e))
    }
  }

  const onEmojiClick = (emoji) => {
    setFormData({
      ...formData,
      title: `${emoji} ${formData.title}`
    })
  }

  return (
    <div>
      <h2>{currentNewsItem ? 'Редактирай': 'Добави'} новина</h2>

      <form onSubmit={handleSubmit} className={classes.form}>
        <textarea name="title" required placeholder="Въведи новина" onChange={handleChange} value={formData.title}></textarea>
        <div style={{height: 90, overflowY: 'scroll'}}>
          {Object.keys(emojis).map(key => {
            const groupEmojisNode = emojis[key].map((emoji, index) => (
              <span style={{cursor: 'pointer', marginBottom: 10, }} key={index} onClick={() => {onEmojiClick(emoji)}}>{emoji} </span>
            ))
            return <div key={key} style={{marginBottom: 20, display: 'flex', gap: 3, maxWidth: '100%', flexWrap: 'wrap'}}>{groupEmojisNode}</div>
          })}
        </div>
        <input name="url" required placeholder="URL на новината" onChange={handleChange} value={formData.url}/>
        <input name="source" placeholder="Източник" onChange={handleChange} value={formData.source}/>
        <select name="topic" onChange={handleChange} value={formData.topic}>
          <option disabled>Избери тема</option>
          {topics.map((topic) => {
            return <option key={topic.key} value={topic.key}>{topic.label}</option>
          })}
        </select>
        <select name="importance" onChange={handleChange} value={formData.importance}>
          <option disabled>Важност</option>
          {importances.map((el) => {
            return (
              <option key={el.key} value={el.key}>
                {el.label}
              </option>
            )
          })}
        </select>
        <input type="submit" value={currentNewsItem ? 'Редактирай': 'Добави'}/>
      </form>
      <LoadingResult async={submitRes} />
    </div>
  );
}

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',

    '& > textarea, & > input, & > select': {
      padding: '12px 8px',
      fontSize: 16,
    },

    '& > textarea': {
      padding: '12px 8px',
      height: '6em',
      
      '&::placeholder': {
        fontSize: 16,
        fontFamily: 'Arial'
      }
    },
  }
})
