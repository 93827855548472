import React from 'react'
import {createUseStyles} from 'react-jss'

export default function Success({message}) {
  const classes = useStyles()
  return message ? (
    <div className={classes.message}>
      {message}
    </div>
  ) : null
}

const useStyles = createUseStyles({
  message: {
    color: 'blue'
  }
})
