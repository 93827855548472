import axios from 'axios'
import { atom, selector, selectorFamily } from 'recoil'

export const newsItemQuery = selectorFamily({
  key: 'NewsItemQuery',
  get: newsItemId => async () => {
    if (!newsItemId) {
      return null
    }

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/${newsItemId}`)
    if (response.error) {
      return null
    }
    return response.data
  },
})
