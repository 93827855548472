import axios from 'axios'
import { atom, selector } from 'recoil'

export const showOnlyActiveAdsState = atom({
  key: 'ShowOnlyActiveAdsState',
  default: true,
})

export const allAdsQuery = selector({
  key: 'AllAdsQuery',
  get: async ({get}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/ads/all`)
    if (response.error) {
      throw response.error
    }

    if (get(showOnlyActiveAdsState)) {
      return response.data.filter(ad => ad.active)
    }
    return response.data
  },
})