import React from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'

import ApexChart from '../../components/ApexChart'
import ErrorBoundary from '../../components/ErrorBoundary'
import { allAdsQuery, showOnlyActiveAdsState } from '../../recoil/ads'

function AdsCharts() {
  const ads = useRecoilValue(allAdsQuery)
  const [showOnlyActive, setShowOnlyActive] = useRecoilState(showOnlyActiveAdsState)

  return (
    <>
      <label>
        <input type="checkbox" checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} style={{marginRight: 10}} />
        Покажи само активните реклами
      </label>
      {ads.map(ad => {
        const clicksFormatted = Object.keys(ad.clicks || []).reduce((acc, item) => {
          acc.periods.push(item)
          acc.data.push(ad.clicks[item].length)
          return acc
        }, {
          periods: [],
          data: []
        })

        return (
          <div key={ad._id} style={{marginTop: 20, paddingTop: 20, borderTop: '1px solid #ccc'}}>
            <img src={ad.image} height={50} alt="" />

            <ApexChart
              series={[{data: clicksFormatted.data, name: 'Кликвания'}]}
              xaxis={clicksFormatted.periods}
              title="Уникални дневни потребители, натиснали върху банера"
            />
          </div>
        )
      })}
    </>
  )
}

export default function Ads() {
  return (
    <div>
      {/* <h3>Реклами / банери</h3> */}

      <ErrorBoundary>
        <React.Suspense fallback={<div>loading...</div>}>
          <AdsCharts />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}
