import React, { useEffect, useState } from 'react'
import { Routes, Route, useRoutes, Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

import ErrorBoundary from './components/ErrorBoundary'
import LogoutButton from './components/LogoutButton'
import LoginButton from './components/LoginButton'
import LoadingResult from './components/LoadingResult'
import Nav from './components/Nav'

import News from './pages/News'
import AddEditNewsItem from './pages/news/AddEditNewsItem'
import CurrentWeekNews from './pages/news/CurrentWeekNews'
import CopyLastDailyNews from './pages/news/CopyLastDailyNews'
import Stats from './pages/Stats'
import Views from './pages/stats/Views'
import Users from './pages/stats/Users'
import Ads from './pages/stats/Ads'
import Settings from './pages/Settings'

import { fetchCurrentWeekNews, fetchCurrentNewsletter } from './redux/reducers/news'
import a from './redux/asyncs'

// https://blog.webdevsimplified.com/2022-07/react-router/

export default function App() {
  const dispatch = useDispatch()
  const [adminEmailsAsync, setAdminEmailsAsync] = useState(a.asyncPending)
  const { isLoading, user, isAuthenticated } = useAuth0()

  useEffect(() => {
    dispatch(fetchCurrentWeekNews(true))
    dispatch(fetchCurrentNewsletter({ interval: 'daily' }))

    axios.get(`${process.env.REACT_APP_API_URL}/admin`)
      .then(function (response) {
        setAdminEmailsAsync(a.asyncDone((response.data || []).map(admin => admin.email)))
      })
      .catch(function (error) {
        setAdminEmailsAsync(a.asyncError(error))
      })
  }, [dispatch])

  const addEditNewsItemSuspensed = (
    <React.Suspense fallback={<LoadingResult isLoading={true} />}>
      <ErrorBoundary>
        <AddEditNewsItem />
      </ErrorBoundary>
    </React.Suspense>
  )

  const routeObject = [
    {
      path: '/',
      element: <Navigate to='/news' />
    },
    {
      path: '/news',
      element: <News />,
      children: [
        { index: true, element: addEditNewsItemSuspensed },
        { path: 'add', element: addEditNewsItemSuspensed },
        { path: 'edit/:userId', element: addEditNewsItemSuspensed },
        { path: 'weekly-current', element: <CurrentWeekNews /> },
        { path: 'daily-last-copy', element: <CopyLastDailyNews /> },
      ],
    },
    {
      path: '/stats',
      element: <Stats />,
      children: [
        { index: true, element: <Views /> },
        { path: 'views', element: <Views /> },
        { path: 'users', element: <Users /> },
        { path: 'ads', element: <Ads /> },
      ],
    },
    {
      path: '/settings',
      element: <Settings />
    },
  ]

  const routes = useRoutes(routeObject)

  return (
    <div style={{
      width: 1024,
      maxWidth: '96%',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      // height: '90%',
      minHeight: '95%',
      marginTop: '1em',
      padding: '2em',
      backgroundColor: 'white',
    }}>
      <LoadingResult
        async={adminEmailsAsync}
        isLoading={isLoading}>
        <>
          <LoginButton />
          {isAuthenticated && (
            <>
            {(adminEmailsAsync.loadResult || []).includes((user.email || '').toLowerCase()) ? (
              <>
                <Nav />
                {routes}
              </>
            ) : (
              <div>
                <p>Този акаунт няма достъп до админ панела.</p>
                <p>Моля влез с имейл, който има права до панела.</p>
                <LogoutButton />
              </div>
            )}
            </>
          )}
        </>
      </LoadingResult>
    </div>
  )
}
