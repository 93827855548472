import React from 'react'
import { Puff } from 'react-loading-icons'
import {createUseStyles} from 'react-jss'
import Error from './Error'
import Success from './Success'

export default function LoadingResult({ async, isLoading, children, style = {} }) {
  const classes = useStyles()

  if (async && async.loadError) {
    return (
      <div className={classes.loadingRes} style={style} >
        <Error error={async.loadError} />
      </div>
    )
  }

  if (isLoading || (async && (async.isLoading || async.isPending))) {
    return (
      <div className={classes.loadingRes} style={{height: '100%', ...style}} >
        <Puff stroke="black" />
      </div>
    )
  }

  if (async && async.isDone && async.loadResult) {
    if (async.loadResult.message) {
      return (
        <div className={classes.loadingRes} style={style} >
          <Success message={async.loadResult.message} />
        </div>
      )
    }

    if (async.loadResult.acknowledged) {
      return (
        <div className={classes.loadingRes} style={style} >
          <Success message="Операцията беше извършена успешно" />
        </div>
      )
    }
  }

  if (children) {
    return children
  }

  return 
}

const useStyles = createUseStyles({
  loadingRes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 12,
  }
})
