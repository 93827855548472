import { current } from '@reduxjs/toolkit';
import axios from 'axios'
import { selector } from 'recoil'

const dma14 = 14

function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date).toISOString().split('T')[0]);
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

const allDatesFromFirstUserToNow = getDatesInRange(new Date('2022-12-06'), new Date())
const allDatesFromFirstUserToNowObject = allDatesFromFirstUserToNow.reduce((acc, item) => {
  return { ...acc, [item]: 0}
}, {})

export const allUsersQuery = selector({
  key: 'AllUsersQuery',
  get: async ({get}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/all`)
    if (response.error) {
      throw response.error
    }
    return response.data
  },
})

export const usersPerDateState = selector({
  key: 'UsersPerDateState',
  get: ({get}) => {
    const allUsers = get(allUsersQuery)

    // Find all users that are duplicate by expo token (something causes a bug with mongodb)
    const usersByTokenObj = allUsers.reduce((acc, item) => {
      if (!acc[item.expoPushToken]) {
        acc[item.expoPushToken] = 0
      }

      acc[item.expoPushToken]++

      return acc
    }, {})
    const usersByTokenArray = Object.entries(usersByTokenObj)
    const duplicateUsersByToken = usersByTokenArray.filter(([key, value]) => value > 1).map(([key, value]) => key)

    // new users per date
    const usersPerDate = allUsers.reduce((acc, item) => {
      const date = (item.createdAt || 'unknown').substring(0, 10)

      if (item.username === undefined) {
        acc.unfinished[date]++
      } else {
        acc.finished[date]++
      }

      acc.total[date]++

      return acc
    }, {
      total: {...allDatesFromFirstUserToNowObject},
      finished: {...allDatesFromFirstUserToNowObject},
      unfinished: {...allDatesFromFirstUserToNowObject}
    })

    const usersPerDayArray = Object.values(usersPerDate.total)

    const getAverageFor14DMAItem = (currentIndex) => {
      let sum = 0
      for(var i = Math.max(0, (currentIndex - dma14 - 1)); i<=currentIndex; i++) {
        sum += usersPerDayArray[i]
      }
      return Math.round(sum / dma14)
    }

    usersPerDate.total14DMA = [...usersPerDayArray].map((count, index) => {
      if (index < dma14) {
        return null
      }
      return getAverageFor14DMAItem(index)
    })

    // users that haven't finished their setup
    const usersWithoutUsername = allUsers.filter(user => !user.username)

    return {
      allUsers,
      duplicateUsersByToken,
      usersPerDate,
      usersWithoutUsername,
    };
  },
});