import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { createUseStyles } from 'react-jss'

import LoadingResult from '../../components/LoadingResult'
dayjs.extend(utc)

export default function CopyLastDailyNews() {
  const classes = useStyles()
  const currentNewsletter = useSelector(state => state.news.currentNewsletter)
  const [isCopied, setCopied] = useState(false)

  const handleCopy = () => {

    const selection = window.getSelection();
    const strongs = document.getElementsByTagName('table');

    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }

    for (const node of strongs) {
      const range = document.createRange();
      range.selectNode(node);
      selection.addRange(range);
    }

    navigator.clipboard.writeText(selection);

    setCopied(true)
  }

  return (
    <div>
      <h2>Новините от последния дневен бюлетин</h2>

      <small>Това са всички новини, които са част от последния бюлетин. Можеш да се копират и да се пействат в Telegram, FB или Substack.</small>

      <br/><br/>
      <button onClick={handleCopy}>{isCopied ? 'Новините са копирани' : 'Копирай всички новини'}</button>

      <table className={classes.table}>
        <thead>
          <tr>
            <th width="100%"><p>Новините за {dayjs().format('DD.MM.YYYY')}г. 👇🏻</p></th>
          </tr>
        </thead>
        <tbody>
          <LoadingResult async={currentNewsletter}>
            {(currentNewsletter.loadResult || []).map((item, key) => {
              return (
                <tr key={key}>
                  <td><p>{item.title}</p></td>
                </tr>
              )
            })}
            {(currentNewsletter.loadResult || []).length === 0 && (
              <tr><td>Няма добавени новини от последното седмично известие</td></tr>
            )}
          </LoadingResult>
        </tbody>
      </table>
    </div>
  );
}

const useStyles = createUseStyles({
  table: {
    '& > tbody > tr > td': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  }
})
