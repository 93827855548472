import React from 'react'
import { NavLink, Outlet, useLocation, useRoutes } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

export default function News() {
  const currentWeekNews = useSelector(state => state.news.currentWeekNews)
  const currentNewsletter = useSelector(state => state.news.currentNewsletter)
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <div>
      <nav>
        <ul className={classes.ul}>
          <li><NavLink to="/news/add" className={pathname === '/news' && 'active'}>Добави новина</NavLink></li>
          <li><NavLink to="/news/weekly-current">Новини от седмицата ({currentWeekNews.loadResult && currentWeekNews.loadResult.length})</NavLink></li>
          <li><NavLink to="/news/daily-last-copy">Новини от последния дневен бюлетин ({currentNewsletter.loadResult && currentNewsletter.loadResult.length})</NavLink></li>
        </ul>
      </nav>

      <Outlet />
    </div>
  )
}

const useStyles = createUseStyles({
  ul: {
    listStyle: 'none',
    padding: '0 0 1.5em',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    borderBottom: '1px solid #ccc',

    '& > li > a': {
      color: 'blue',

      '&:hover': {
        textDecoration: 'none'
      },

      '&.active': {
        color: 'black',
        textDecoration: 'none',
        cursor: 'default',
      }
    }
  },
})
