import React  from 'react'
import { useRecoilValue } from 'recoil'

import ApexChart from '../../components/ApexChart'
import ErrorBoundary from '../../components/ErrorBoundary'
import { usersPerDateState } from '../../recoil/users'

function GeneralUserStats() {
  const { allUsers, usersWithoutUsername, duplicateUsersByToken } = useRecoilValue(usersPerDateState)

  return (
    <div>
      <h2>Oбща информация</h2>
      <dl>
        <dt>Потребители в базата</dt>
        <dd>{allUsers.length}</dd>
      </dl>

      <dl>
        <dt>Потребители, които не са си завършили персонализацията</dt>
        <dd>{usersWithoutUsername.length}</dd>
      </dl>

      <dl>
        <dt>Потребители с дублиран expo token</dt>
        <dd>
          <details>
          <summary>{duplicateUsersByToken.length}</summary>
            {duplicateUsersByToken.map((token, index) => <div key={index}>{token}</div>)}
          </details>
        </dd>
      </dl>
    </div>
  )
}

function UsersPerDate() {
  const { usersPerDate } = useRecoilValue(usersPerDateState)

  return (
    <>
      <h2 style={{marginTop: 50}}>Исторически данни</h2>
      <ApexChart
        series={[
          {data: Object.values(usersPerDate.total), name: 'Нови инсталации'},
          {data: Object.values(usersPerDate.total14DMA), name: '14 DMA'},
          {data: Object.values(usersPerDate.finished), name: 'Реални потребители'},
          {data: Object.values(usersPerDate.unfinished), name: 'Незавършили персонализацията'}
        ]}
        type="line"
        xaxis={Object.keys(usersPerDate.total)}
        title="Нови потребители за ден"
      />
    </>
  )
}

export default function Users() {
  return (
    <div>
      <ErrorBoundary>
        <React.Suspense fallback={<div>loading...</div>}>
          <GeneralUserStats />
          <UsersPerDate />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}
