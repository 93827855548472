import React from 'react'
import { useRecoilValue } from 'recoil'

import ApexChart from '../../components/ApexChart'
import ErrorBoundary from '../../components/ErrorBoundary'
import { uniqueViewsPerDayQuery } from '../../recoil/views'

function ViewsPerDay() {
  const views = useRecoilValue(uniqueViewsPerDayQuery)

  return (
    <ApexChart
      series={[{data: views.map(view => view.count), name: 'Дневни отваряния'}]}
      xaxis={views.map(view => view._id)}
      title="Уникални дневни потребители, отваряли новините"
    />
  )
}

export default function Views() {
  return (
    <div>
      {/* <h3>Прегледи</h3> */}

      <ErrorBoundary>
        <React.Suspense fallback={<div>loading...</div>}>
          <ViewsPerDay />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}
