import axios from 'axios'
import { selector } from 'recoil'

export const uniqueViewsPerDayQuery = selector({
  key: 'UniqueViewsPerDayQuery',
  get: async ({get}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/views/per/day`)
    if (response.error) {
      throw response.error
    }
    console.log('data: ', response.data)
    return response.data
  },
})