import React from 'react'
import {createUseStyles} from 'react-jss'

export default function Error({error}) {
  let safeError = error.message || error
  const classes = useStyles()
  return safeError ? (
    <div className={classes.error}>
      {safeError}
    </div>
  ) : null
}

const useStyles = createUseStyles({
  error: {
    color: 'red'
  }
})
