import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import a from '../asyncs'

const initialState = {
  currentWeekNews: a.asyncEmpty(),
  currentNewsletter: a.asyncEmpty(),
  // ...other news...
}

export const fetchCurrentWeekNews = createAsyncThunk(
  'news/fetchCurrentWeekNews',
  async (startPending, tnk) => {
    startPending && tnk.dispatch(setCurrentWeekNews(a.asyncPending()))

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/news/after/notification/weekly`)
      tnk.dispatch(setCurrentWeekNews(a.asyncDone(res.data)))
    } catch (e) {
      tnk.dispatch(setCurrentWeekNews(a.asyncError(e)))
    }
  }
)

export const fetchCurrentNewsletter = createAsyncThunk(
  'news/fetchCurrentNewsletter',
  async ({ interval = 'daily', startPending } , { dispatch }) => {
    // startPending && dispatch(setCurrentWeekNews(a.asyncPending()))

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/news/between/lasttwonotifications/${interval}`)
      dispatch(setCurrentNewsletter(a.asyncDone(res.data)))
    } catch (e) {
      dispatch(setCurrentNewsletter(a.asyncError(e)))
    }
  }
)

export const news = createSlice({
  name: 'news',
  initialState: {
    ...initialState,
  },
  reducers: {
    reset: () => initialState,
    setCurrentWeekNews: (state, action) => {
      state.currentWeekNews = action.payload
    },
    setCurrentNewsletter: (state, action) => {
      state.currentNewsletter = action.payload
    },
    updateItemFromCurrentWeekNews: (state, action) => {
      state.currentWeekNews = a.asyncDone(state.currentWeekNews.loadResult.map((item) => {
        if (item._id === action.payload._id) {
          return {
            ...item,
            ...action.payload
          }
        }
        return item
      }))

    }
  }
})

export const {
  reset,
  updateItemFromCurrentWeekNews,
  setCurrentNewsletter,
  setCurrentWeekNews,
} = news.actions;

export default news.reducer