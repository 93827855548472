import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export default function LoginButton() {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  if (!isAuthenticated) {
    return (
      <div style={{textAlign: 'center', marginTop: '2em'}}>
        <button onClick={() => loginWithRedirect()}>
          Log In
        </button>
      </div>
    )
  }
}
