import React from 'react'
import Chart from "react-apexcharts"

const ApexChart = ({ title, series, xaxis, type = 'bar', alignTitle = 'center' }) => (
  <div id="chart">
    <Chart
      options={{
        chart: {
          height: 350,
          zoom: {
            enabled: false
          }
        },
        title: {
          text: title,
          align: alignTitle
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: xaxis,
        }
      }}
      series={series}
      type={type}
      height={350}
    />
  </div>
)

export default ApexChart